<template>
	<div class="table">
		<div class="crumbs">
		    <el-breadcrumb separator="/">
		        <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 内容管理</el-breadcrumb-item>
		    </el-breadcrumb>
		</div>
		<div class="container">
			<div class="handle-box">
				<el-input v-model="select_word" placeholder="筛选关键词(标题/医生)" class="handle-input mr10" @keyup.enter.native="searchEnterFun"></el-input>
				<el-button type="primary" icon="search" @click="search">搜索</el-button>
				<el-select v-model="is_rec_status" class="mr10" @change="change">
				    <el-option
				            v-for="item in recStatusList"
				            :key="item.id"
				            :label="item.title"
				            :value="item.id">{{item.title}}
				    </el-option>
				</el-select>
				<el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加</el-button>
			</div>
			<el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
				<el-table-column prop="id" align="center" label="ID"></el-table-column>
				<el-table-column prop="title" align="center" label="视频标题"></el-table-column>
				<el-table-column prop="cover_url" align="center" label="视频封面">
					<template   slot-scope="scope">
					    <el-popover
					            placement="left"
					            title=""
					            width="500"
					            trigger="hover">
					        <img :src="scope.row.cover_url" width="150" style="max-width: 100%" />
					        <img slot="reference" :src="scope.row.cover_url" :alt="scope.row.cover_url" style="max-width: 80px; height: auto; max-height: 100px">
					    </el-popover>
					    <!--<img :src="scope.row.b_image"  min-width="70" height="70" />-->
					</template>
				</el-table-column>
<!-- 				<el-table-column prop="doctor_name" align="center" label="医生"></el-table-column> -->
				<el-table-column prop="is_rec_txt" align="center" label="推荐">
					<template slot-scope="scope">
						<p v-if="scope.row.is_recommend ==1" style="color: green;">是</p>
						<p v-else>否</p> 
					</template>
				</el-table-column>
				<el-table-column prop="like_number" align="center" label="点赞量"></el-table-column>
				<el-table-column prop="sort" align="center" label="排序"></el-table-column>
				<el-table-column prop="create_time" align="center" label="发布时间"></el-table-column>
				<el-table-column label="操作" align="center">
				    <template slot-scope="scope">
				        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">编辑</el-button>
				        <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
						  <el-button type="text" icon="el-icon-menu" @click="handleShow(scope.$index, scope.row)">查看评论</el-button>
				    </template>
				</el-table-column>
			</el-table>
			<div class="pagination">
			    <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
			    </el-pagination>
			</div>
		</div>
		<el-dialog title="编辑" v-loading="loading" :visible.sync="editVisible" width="70%">
			<el-form ref="form" :rules="rules" :model="form" label-width="100px">
				<el-form-item label="视频标题">
				    <el-input v-model="form.title" style="width:400px" placeholder="请输入视频标题"></el-input>
				</el-form-item>
				
				<el-form-item label="视频分类" prop="categoryid">
				    <el-select v-model="form.categoryid">
				        <el-option-group
				                v-for="group in categoryList"
				                :key="group.id"
				                :label="group.title">
				            <el-option
				                    v-for="item in group.subs"
				                    :key="item.id"
				                    :label="item.title"
				                    :value="item.id">
				            </el-option>
				        </el-option-group>
				    </el-select>
				</el-form-item>				
				
				<el-form-item label="封面">
					<el-upload
					        class="avatar-uploader"
					        name="image"
					        with-credentials
					        :data="{id:this.form.cover}"
					        :action="uploadUrl()"
					        :on-error="uploadError"
					        :on-success="handleCoverSuccess"
					        :before-upload="beforeCoverUpload"
					        :on-progress="uploading"
					        :show-file-list="false"
					        :auto-upload="true"
					        enctype="multipart/form-data">
					    <img v-if="form.cover_url" :src="form.cover_url" class="avatar">
					    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<span style="color:red">建议尺寸200*200</span>
				</el-form-item>
				<el-form-item label="视频">
				    <el-upload class="avatar-uploader" 
							:data="{id:this.form.VideoId}" 
							name="video" 
							:action="uploadVideoUrl()" 
							:show-file-list="false" 
							:on-success="handleVideoSuccess" 
							:before-upload="beforeUploadVideo" 
							:on-progress="uploadVideoProcess">
				        <video v-if="this.form.video !='' && videoFlag == false" :src="this.form.video" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
				        <i v-else-if="this.form.video =='' && videoFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
				        <el-progress v-if="videoFlag == true" type="circle" :percentage="videoUploadPercent" style="margin-top:30px;"></el-progress>
				    </el-upload> 
				    <P class="text" style="color:red">请保证视频格式正确，且不超过50M</P>
				</el-form-item>
				<el-form-item label="点赞量">
				    <el-input v-model="form.like_number" style="width:400px" placeholder="请输入获赞数量"></el-input>
				</el-form-item>
				<el-form-item label="排序">
					<el-input v-model="form.sort" style="width:150px"></el-input>
					<span style="color:red">&nbsp;&nbsp;注：数值越大展示越靠前，不输入则默认排序</span>
				</el-form-item>
				<el-form-item label="是否推荐">
				    <el-switch v-model="form.is_recommend" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
<!-- 				<el-form-item label="关联医生">
				    <el-select v-model="form.doctor_id">
				        <el-option
				                v-for="item in doctorList"
				                :key="item.id"
				                :label="item.doctor_name"
				                :value="item.id">{{item.doctor_name}}
				        </el-option>
				    </el-select>
				</el-form-item> -->
				<el-form-item label="关联商品">
				    <el-checkbox-group v-model="type">
				        <el-checkbox v-for="item in goodsList" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
				    </el-checkbox-group>
				</el-form-item>
				
				
				<el-form-item label="文章内容">
					
					<vue-ueditor-wrap v-model="form.content" :config="myConfig"></vue-ueditor-wrap>
				
				</el-form-item>			
				
				
			</el-form>	
			
			
			<span slot="footer" class="dialog-footer">
				<el-button @click="editVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveEdit('form')">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 删除提示框 -->
		<el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
		    <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
		    <span slot="footer" class="dialog-footer">
		        <el-button @click="delVisible = false">取 消</el-button>
		        <el-button type="primary" @click="deleteRow">确 定</el-button>
		    </span>
		</el-dialog>
	</div>
</template>
<script>
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import {quillEditor, Quill} from 'vue-quill-editor'
	import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
	Quill.register('modules/ImageExtend', ImageExtend)
	export default {
	    name: 'basetable',
		data(){
			return{
				url: './vuetable.json',
				tableData:[],
				cur_page: 1,  //当前页
				number: 10,  //每页显示条数
				sumPage:10,  //总页数
				multipleSelection: [],
				select_word:'',
				del_list: [],
				form:{
					id:'',
					doctor_id:'',
					title:'',
					like_number:'',
					sort:'',
					is_recommend:'',
					sort:'',
					video:'',
					VideoId:null,
					cover:'',
					cover_url:'',
					categoryid: '',	
					content: '',
				},
				loading:false, //加载中
				AddOrSave:'',  //1表示添加，2表示更新
				editVisible: false,
				delVisible: false,
				goodsList: [],  //商品列表
				type:[],
				doctorList:[], //医生列表
				videoFlag: false,
				videoUploadPercent: 0,
				rules:{
					// doctor_id:[
					// 	 { required: true, message: '请选择关联医生', trigger: 'change' }
					// ],
					categoryid:[
                        { required: true, message: '请选择分类', trigger: 'change' }
                    ],
				},
				is_search: false,
				is_rec_status:'',
				categoryList:[],  //分类列表
				recStatusList:[
					{id:'',title:'全部'},
					{id:1,title:'推荐'},
					{id:0,'title':'不推荐'}
				],
				myConfig: {
				    // 编辑器不自动被内容撑高
				    autoHeightEnabled: false,
				    // 初始容器高度
				    initialFrameHeight: 600,
				    // 初始容器宽度
				    initialFrameWidth: '90%',
				    zIndex: 9999,
				    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				    serverUrl: this.$api.editorUrl,
				    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				    UEDITOR_HOME_URL: '/ueditor/'
				},
				 
			}
		},
		created(){
			//初始数据	
			this.getData();
		},
		computed:{
			data(){
				return this.tableData.filter((d) => {
				    let is_del = false;
				    for (let i = 0; i < this.del_list.length; i++) {
				        if (d.title === this.del_list[i].title) {
				            is_del = true;
				            break;
				        }
				    }
				    return d;
				})
			}
		},
		methods: {
			
			//获取分类
			getCategoryList(){
				this.$api.post('ShopHospitalVideo/getCategoryList', null, res => {
					console.log(res.data);
					this.categoryList=res.data;
				}, err => {
					this.$message.error(err.msg);
				});
			},			
			
			// 分页导航
			handleCurrentChange(val) {
			    this.cur_page = val;
			    this.getData();
			},
			
			handleSelectionChange(val){
				  this.multipleSelection = val;
			},
			//视频上传之前
			beforeUploadVideo(file) {
				const isLt10M = file.size / 1024 / 1024  < 50;
				if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
					this.$message.error('请上传正确的视频格式');
					return false;
				}
				if (!isLt10M) {
					this.$message.error('上传视频大小不能超过50MB哦!');
					return false;
				}
			},
			//视频上传进度
			uploadVideoProcess(event,file,fileList){
				this.videoFlag = true
				this.videoUploadPercent =  parseInt(file.percentage.toFixed(0))
			},
			//视频上传成功
			handleVideoSuccess(res,file){
				this.videoFlag =  false
				this.videoUploadPercent =  0
				if(res.success){
					this.form.VideoId =  res.data.id
					this.form.video =  res.data.videoUrl
				    this.$message.success(res.msg);
				}else{
				    this.$message.error('视频上传失败，请重新上传！');
				}
			},
			//设置上传视频接口地址
			uploadVideoUrl(){
			    var url=this.$api.uploadUrl + "/Images/uploadVideo";
			    return url
			},
			//设置上传图片接口地址
			uploadUrl(){
			    var url=this.$api.uploadUrl + "/Images/upload";
			    return url
			},
			//图片上传之前
			beforeCoverUpload(file){
			     // console.log(this.form.pic);
			     // console.log(file);
			    this.loading=true;
			},
			//正在上传中
			uploading(event, file, fileList){
			    // console.log(event);
			    // console.log(file);
			    // console.log(fileList);
			},
			//视频封面上传成功
			handleCoverSuccess(res, file){
			    this.loading=false;
			    console.log(res);
			    this.form.cover=res.data;
			    this.form.cover_url = URL.createObjectURL(file.raw);
			    this.$message.success(res.msg);
			},
			//图片上传失败
			uploadError(err){
			    this.$message.error(err.msg);
			},
			change(){
			    // console.log(this.selectedStatus);
			    this.getData();
			},
			//获取表格数据
			getData(){
				var params = this.$qs.stringify({
					select_word:this.select_word,
					pageIndex: this.cur_page,
					number: this.number,
					is_rec_status:this.is_rec_status,
				})
				this.$api.post('ShopHospitalVideo/getVideoList',params,res=>{
					this.tableData =  res.data.list
					this.sumPage = res.data.sumPage*10;
					this.cur_page = res.data.currentPage;
					// console.log(tableData)
					// console.log(res)
				},err=>{
					this.tableData = [];
					this.sumPage = 1*10;
					this.cur_page = 1;
					this.$message.error(err.msg);
				})
		    },
			//按下回车键发起搜索
			searchEnterFun:function(e){
			    var keyCode = window.event? e.keyCode:e.which;
			    if(keyCode == 13){
			        this.search();
			    }
			},
			search() {
			    this.is_search = true;
			    this.getData();
			},
			handleDelete(index, row) {
			    this.idx = index;
			    this.form = row;
			    this.delVisible = true;
			},
			
			//查看评论
			handleShow(index,row){
				this.$router.push({path:'/ShopHospitalCommentsTable',query:{v_id:row.id}})
			},
		    handleEdit(index, row, status) {
				this.getDoctorList()
				this.getGoodsList()
				this.getCategoryList();
				this.AddOrSave = status 
				if(status == 1){	//添加  清空表单
					this.form = {
						id:null,
						doctor_id:null,
						title:'',
						like_number:null,
						sort:null,
						is_recommend:null,
						sort:null,
						video:'',
						VideoId:null,
						cover:null,
						cover_url:null,
						categoryid:null,
						content: '',
					}
					  this.type=[];
				}
				if(index!=undefined && row != undefined){
					this.idx =  index;
					const item =  this.tableData[index]
					console.log(item)
					this.form ={
						id:item.id,
						// doctor_id:item.doctor_id,
						title:item.title,
						like_number:item.like_number,
						is_recommend:parseInt(item.is_recommend),
						sort:item.sort,
						video:item.video_url,
						VideoId:item.video>1?item.video:null,
						cover:item.cover,
						cover_url:item.cover_url,
						categoryid: item.categoryid,
						content: item.content,
					}
					this.type=[];	
					for(var i=0;i<row.goodsList.length;i++){
					    this.type.push(row.goodsList[i]['id']);
					}
				}
				this.editVisible =  true
			},
			//获取医生列表
			getDoctorList(){
			    this.$api.post('ShopCase/getDoctorList', null, res => {
			        // console.log(res);
			        this.doctorList=res.data;
			    }, err => {
			        this.$message.error(err.msg);
			    });
			},
			//获取商品列表
			getGoodsList(){
			    this.$api.post('ShopCoupon/getGoodsList', null, res => {
			        // console.log(res);
			        this.goodsList=res.data;
			    }, err => {
			        this.$message.error(err.msg);
			    });
			},
			saveEdit(formName) {
				console.log('111111')
				this.$refs[formName].validate((valid) => {
					if(valid){
						this.editVisible = false;
						var params=null;
						// console.log(this.AddOrSave)
						if(!this.form.title){
							this.$message.error('请填写标题');
							return false;
						}
						if(!this.form.cover){
							this.$message.error('请上传封面');
							return false;
						}
						// if(!this.form.VideoId){
						// 	this.$message.error('请上传视频');
						// 	return false;
						// }
						// if(!this.form.doctor_id){
						// 	this.$message.error('请关联医生');
						// 	return false;
						// }
						
						//1表示添加，2表示更新
						if(this.AddOrSave==1){
							params=this.$qs.stringify({
								title: this.form.title,
								// doctor_id: this.form.doctor_id,
								goods_ids:this.type.join(','),
								video:this.form.VideoId,
								like_number:this.form.like_number, 
								is_recommend:this.form.is_recommend?1:0,
								sort:this.form.sort,
								cover:this.form.cover,
								categoryid: this.form.categoryid,
								content: this.form.content,
							});
						}else{
							params=this.$qs.stringify({
								id: this.form.id,
								title:this.form.title,
								// doctor_id: this.form.doctor_id,
								goods_ids:this.type.join(','),
								video:this.form.VideoId,
								like_number:this.form.like_number,
								is_recommend:this.form.is_recommend?1:0,
								sort:this.form.sort,
								cover:this.form.cover,
								categoryid: this.form.categoryid,
								content: this.form.content,
							});
						} 
						this.$api.post('ShopHospitalVideo/addVideo', params, res => {
							this.getData();
							this.$message.success(res.msg);
							// console.log(res);
						}, err => {
							this.$message.error(err.msg);
						});
					}else{
						console.log("请填写所需数据");
						return false;
					}
				})
			},
			// 确定删除
			deleteRow(){
				var params=this.$qs.stringify({
					id: this.form.id
				});
				this.$api.post('ShopHospitalVideo/delVideo', params, res => {
					this.getData();
					this.$message.success(res.msg+res.data+"条数据");
				}, err => {
					this.$message.error(err.msg);
				});
				this.delVisible = false;
			}
		},		

	}
</script>

<style>
	.handle-box {
	    margin-bottom: 20px;
	}
	
	.handle-select {
	    width: 120px;
	}
	
	.handle-input {
	    width: 300px;
	    display: inline-block;
	}
	.del-dialog-cnt{
	    font-size: 16px;
	    text-align: center
	}
	.table{
	    width: 100%;
	    font-size: 14px;
	}
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	}
	.avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 178px;
	    height: 178px;
	    line-height: 178px;
	    text-align: center;
	}
	.avatar {
	    width: 100%;
	    /*height: 100%;*/
	    display: block;
	}
</style>
